import {createStylesParams} from '@wix/tpa-settings';
import {baseStylesParams, IStylesParams, StylesParamKeys} from '../../baseStylesParams';
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor';
import {baseCustomCssVars, CustomCssVarsFnParams} from '../../baseCustomCssVars';

export const customCssVars: CustomCssVarsFn = (params: CustomCssVarsFnParams) => ({
  ...baseCustomCssVars(params),
});

const stylesParams: IStylesParams = {
  ...baseStylesParams,
};

export default createStylesParams<StylesParamKeys>(stylesParams);
